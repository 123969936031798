import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  AppBar,
  Collapse,
  Drawer,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
} from '@mui/material';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import LogoutIcon from '@mui/icons-material/Logout';
import LoginIcon from '@mui/icons-material/Login';
import MenuIcon from '@mui/icons-material/Menu';
import ReceiptIcon from '@mui/icons-material/Receipt';
import GestureIcon from '@mui/icons-material/Gesture';
import { useTheme } from '@mui/material/styles';

const sigItems = [
  { text: 'Cortina', href: '/signature/ck' },
  { text: 'Niico', href: '/signature/niico' },
];

export const SubNav = ({ icon, primary, items }) => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const wrapperRef = useRef(null);

  const handleClick = () => {
    setOpen(!open);
  };

  // Function to handle closing the sub-menu if the click is outside of the component
  const handleClickOutside = event => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setOpen(false);
    }
  };

  // Listen for clicks outside of the component to close the sub-menu
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div ref={wrapperRef}>
      <ListItem button onClick={handleClick}>
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText primary={primary} style={{ color: theme.palette.primary.main }} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {items.map((item, index) => (
            <Link href={item.href} key={index}>
              <ListItem button>
                <ListItemText
                  sx={{ '& .MuiListItemText-primary': { textDecoration: 'none' } }}
                  inset
                  primary={item.text}
                />
              </ListItem>
            </Link>
          ))}
        </List>
      </Collapse>
    </div>
  );
};

SubNav.propTypes = {
  icon: PropTypes.element.isRequired,
  primary: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      href: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export const Sidebar = ({ isAuthenticated, user, groups }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDrawer = open => event => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setIsOpen(open);
  };

  return (
    <>
      <AppBar position="relative">
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={toggleDrawer(true)}
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer anchor="top" open={isOpen} onClose={toggleDrawer(false)}>
        <List>
          {user?.is_staff && (
            <Link href="/api/admin" underline="hover">
              <ListItem button key="admin">
                <ListItemIcon>
                  <AdminPanelSettingsIcon color="primary" />
                </ListItemIcon>
                <ListItemText primary="Admin" />
              </ListItem>
            </Link>
          )}
          <SubNav
            primary="Signature Generator"
            icon={<GestureIcon color="primary" />}
            items={sigItems}
          />
          {isAuthenticated && groups?.includes('Card') && (
            <Link href="/reports" underline="hover">
              <ListItem button key="logs">
                <ListItemIcon>
                  <ReceiptIcon color="primary" />
                </ListItemIcon>
                <ListItemText primary="Purchase Logs" />
              </ListItem>
            </Link>
          )}
          {isAuthenticated && (
            <Link href="/forms/qcc" underline="hover">
              <ListItem button key="qcc">
                <ListItemIcon>
                  <AssignmentTurnedInIcon color="primary" />
                </ListItemIcon>
                <ListItemText primary="Quality Check Completion Forms" />
              </ListItem>
            </Link>
          )}
          {isAuthenticated && groups?.includes('Schedule') && (
            <Link href="/schedule" underline="hover">
              <ListItem button key="schedule">
                <ListItemIcon>
                  <CalendarMonthIcon color="primary" />
                </ListItemIcon>
                <ListItemText primary="Schedule" />
              </ListItem>
            </Link>
          )}
          {!isAuthenticated && (
            <Link href="/api/login" underline="hover">
              <ListItem button key="logs">
                <ListItemIcon>
                  <LoginIcon color="primary" />
                </ListItemIcon>
                <ListItemText primary="Login" />
              </ListItem>
            </Link>
          )}
          {isAuthenticated && (
            <Link href="/support" underline="hover">
              <ListItem button key="support">
                <ListItemIcon>
                  <SupportAgentIcon color="primary" />
                </ListItemIcon>
                <ListItemText primary="Support" />
              </ListItem>
            </Link>
          )}
          {isAuthenticated && (
            <Link href="/api/logout" underline="hover">
              <ListItem button key="logs">
                <ListItemIcon>
                  <LogoutIcon color="primary" />
                </ListItemIcon>
                <ListItemText primary="Logout" />
              </ListItem>
            </Link>
          )}
        </List>
      </Drawer>
    </>
  );
};

Sidebar.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  user: PropTypes.shape({
    username: PropTypes.string,
    email: PropTypes.string,
    is_superuser: PropTypes.bool,
    is_staff: PropTypes.bool,
    full_name: PropTypes.string,
  }),
  groups: PropTypes.arrayOf(PropTypes.string).isRequired,
};

Sidebar.defaultProps = {
  user: {
    username: '',
    email: '',
    is_superuser: false,
    is_staff: false,
    full_name: '',
  },
};
